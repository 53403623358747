import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { useEffect } from "react";

import { useSessionStorage } from '@uidotdev/usehooks';

import Loading from "./components/Loading";
import Error from "./components/Error";

import { Welcome, Dashboard } from "./pages";

const router = createBrowserRouter([
    {
      path: "/auth",
      element: <Welcome />,
      errorElement: <Error />,
      loader: Loading
    },
    {
      path: "/",
      element: <Dashboard />,
      errorElement: <Error />,
      loader: Loading
    }
]);


let wakeLock = null;

export default function App() {

  useEffect(() => {
    (function (d) {
      const appziScript = d.createElement("script");
		appziScript.async = true;
		appziScript.defer = true;
        appziScript.src = `https://w.appzi.io/w.js?token=sNdXX`;
        d.head.appendChild(appziScript);
    })(document);

    return () => false;
  }, []);


  async function documentVisibilityChange() {
    if (
      wakeLock === null &&
      document.visibilityState === "visible" &&
      "wakeLock" in navigator
    ) {
      // reacquire wake lock
      wakeLock = await navigator.wakeLock.request("screen");
    }

    if (
      document.visibilityState === "hidden" &&
      wakeLock !== null &&
      "wakeLock" in navigator
    ) {
      await wakeLock.release();
      wakeLock = null;
    }
  }

  useEffect( () => {
    documentVisibilityChange()
    
    document.addEventListener("visibilitychange", documentVisibilityChange);

    return () => document.removeEventListener("visibilitychange", documentVisibilityChange);
    
  }, []);

  return (
    <RouterProvider router={router} />
  );
}


