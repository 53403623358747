import { twMerge } from "tailwind-merge";
import Circle from "../../icons/circle.svg?react";
import Hello from "../../icons/doctor-hello.svg?react";
import UserIcon from "../../icons/user-icon.svg?react";
import EmailIcon from "../../icons/email-icon.svg?react";
import PrivacyIcon from "../../icons/privacy-shield.svg?react";
import clsx from "clsx";

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { toast } from "react-toastify";

import Select from "react-select";

import { useDocumentTitle, useLocalStorage } from "@uidotdev/usehooks";

import { successOpts, errorOpts, processOpts } from "../../util/toast";

import { useForm } from "react-hook-form";
import { useAuthState } from "react-firebase-hooks/auth";

import {
  auth,
  analytics,
  isValidEmail,
  getToken,
  getAuthError,
  db,
} from "../../util/firebase";

import Loading from "../../components/Loading";

import { useState, useEffect, useRef } from "react";

const TRY_NOW = "trynow";
const ACCEPT_POLICY = "policy";
const INFO = "info";
const SIGNUP = "signup";

const components = {
  [TRY_NOW]: TryNow,
  [INFO]: Info,
  [ACCEPT_POLICY]: Policy,
  [SIGNUP]: Auth,
};

function isGreater(curr, comparator) {
  let curr_idx = Object.keys(components).indexOf(curr);
  let comp_idx = Object.keys(components).indexOf(comparator);
  if (curr_idx === -1 || comp_idx === -1) {
    return false;
  }

  return curr_idx > comp_idx;
}

function getNextScreenKey(curr) {
  let componentsKeys = Object.keys(components);
  let curr_idx = componentsKeys.indexOf(curr);
  curr_idx = (curr_idx + 1) % componentsKeys.length;
  let next_key = componentsKeys[curr_idx];

  return next_key;
}

export default function Welcome() {
  const { pathname } = useLocation();
  const isLogin = pathname.indexOf("login") !== -1;

  const [screen, setScreen] = useState(TRY_NOW);
  const [screenNo, setScreenNo] = useState(
    Object.keys(components).indexOf(screen)
  );

  const navigate = useNavigate();

  const [ user, userLoading, error ] = useAuthState(auth);

  const [ loading, setLoading ] = useState(false);

  const [data, setData] = useState({});

  const getData = () => data;

  const Screen = components[screen];

  useEffect(() => {
    if (Object.keys(components).indexOf(screen) === screenNo)
      return () => false;

    setScreenNo(Object.keys(components).indexOf(screen));

    return () => true;
  }, [screen]);


  useEffect(() => {
	
	if(!auth || userLoading) return () => false;

	if(auth && auth.currentUser) navigate('/');

  }, [user, userLoading, error, auth])


  if(loading) {
	return (
		<div className="w-full h-full flex flex-col flex-grow justify-center items-center relative">
			<img
				src="https://cdn.chiroscript.ai/logo/logo.svg"
				className="mt-4 absolute top-0"
				width={200}
				height={200}
         	 />
			<Loading classes={"w-[4rem]"} />
		</div>
	)
  }


  if (isLogin || screen === SIGNUP) {
    return (
      <Screen
        data={{
          set: setData,
          get: getData,
        }}
        screen={{
          screen,
          screenNo,
        }}
        next={() => setScreen(getNextScreenKey(screen))}
      />
    );
  } else {
    return (
      <div className="w-full h-full bg-page flex flex-col items-center relative">
        <div className="w-full justify-center items-center flex flex-col gap-4">
          <img
            src="https://cdn.chiroscript.ai/logo/logo.svg"
            className="mt-4"
            width={200}
            height={200}
          />
          {isGreater(screen, TRY_NOW) && (
            <button className="w-[40px] h-[40px] fixed left-4 top-4 border-gray-400 rounded-md border-2 justify-center items-center flex transition-all duration-500 ease-in-out shadow-md hover:shadow-none">
              <svg
                width="18"
                height="18"
                viewBox="0 0 16 16"
                fill="#004CA9"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 13.78C9.87335 13.78 9.74669 13.7333 9.64669 13.6333L5.30002 9.28668C4.59335 8.58001 4.59335 7.42001 5.30002 6.71335L9.64669 2.36668C9.84002 2.17335 10.16 2.17335 10.3534 2.36668C10.5467 2.56001 10.5467 2.88001 10.3534 3.07335L6.00669 7.42001C5.68669 7.74001 5.68669 8.26001 6.00669 8.58001L10.3534 12.9267C10.5467 13.12 10.5467 13.44 10.3534 13.6333C10.2534 13.7267 10.1267 13.78 10 13.78Z"
                  fill="#004CA9"
                />
              </svg>
            </button>
          )}
          {isGreater(screen, TRY_NOW) && (
            <div className="flex items-center gap-4">
              {Object.keys(components).map((item, index, arr) => (
                <div
                  key={item}
                  className={twMerge(
                    "relative w-[59px] h-[6px] bg-gray-200",
                    clsx(
                      { "rounded-l-full": index == 0 },
                      { "rounded-r-full": index + 1 == arr.length }
                    )
                  )}
                >
                  <div
                    className={twMerge(
                      "absolute inset-0 from-gradient-start to-gradient-end from-[0%] to-[100%] bg-gradient-90 duration-200 transition origin-left scale-x-0",
                      clsx(
                        { "rounded-l-full": index == 0 },
                        {
                          "scale-x-100": isGreater(screen, item),
                        },
                        { "rounded-r-full": index + 1 == arr.length }
                      )
                    )}
                  ></div>
                </div>
              ))}
            </div>
          )}
        </div>

        {
          <Screen
            data={{
              set: setData,
              get: getData,
            }}
            next={() => setScreen(getNextScreenKey(screen))}
          />
        }
      </div>
    );
  }
}

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  SignInMethod,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

/* When authenticating, I cannot save any user data since its not at all possible. Well for useContext it is, but thats different. */
/* So the question is for the data already saved like ehr, country, how do I save this data? */
/* Answer: Simply store in session storage, see authentication is of type sign up, use data and pass to firestore (update) and redirect */

function Auth({ data, next, screen }) {
  const { pathname } = useLocation();

  const isLogin = pathname.indexOf("login") !== -1;

  const [manualRedirect, setManualRedirect] = useState(false);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const nextParam = searchParams.get("next");

  const [displayNameUpdate, setDisplayNameUpdate] = useLocalStorage(
    "displayName",
    null
  );

  const [user, userLoading, error] = useAuthState(auth);

  const [loading, setLoading] = useState(false);

  const [authMode, setAuthMode] = useState(isLogin ? "login" : "register");

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors, isValid, isLoading },
  } = useForm();

  let [email, password, confirm, displayName] = watch([
    "email",
    "password",
    "confirm",
    "displayName",
  ]);
  const onGoogle = async (event) => {
    
	event?.preventDefault();

    // eslint-disable-next-line no-unused-vars
    const { signInWithPopup, GoogleAuthProvider } = await import(
      "firebase/auth"
    );

    setLoading(true);

    try {
      	await signInWithPopup(auth, new GoogleAuthProvider());

		const { logEvent, setUserId, setUserProperties } = await import(
			"firebase/analytics"
		);

		logEvent(analytics, authMode, {
			method: GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD,
		});

		setUserId(analytics, auth.currentUser.uid, {
			global: true,
		});

      	
    } catch (err) {
     	
		console.error(err, err.code);
      	
		// const { logEvent } = await import("firebase/analytics");

		let formerror;

		let fatal = false;

		if (
			err.code === "auth/internal-error" &&
			err.message.indexOf("Cloud Function") !== -1
		) {
			formerror = err.message.substring(err.message.indexOf("{"), err.message.lastIndexOf("}") + 1);
			formerror = JSON.parse(formerror);
			formerror = formerror.error.message;
		} else {
			formerror = getAuthError(err.code);
			if (err.code === "auth/internal-error") {
				fatal = true;
			}
		}

		setLoading(false);
    }
  };

  useDocumentTitle(
    isLogin ? "Login | chiroscript.ai" : "Register | chiroscript.ai"
  );

  return (
    <div className="flex flex-row w-full h-full bg-page">
      <div className="relative w-full h-full bg-[url('https://cdn.chiroscript.ai/dashboard/left-image.png')] bg-cover bg-no-repeat">
        <div className="absolute top-0 left-0 w-full h-full from-gradient-start/70 to-gradient-end/70 from-[0%] to-[100%] bg-gradient-180"></div>
      </div>
      <div className="w-full h-full bg-page flex flex-col items-center relative">
        <div className="w-full justify-center items-center flex flex-col gap-4">
          <img
            src="https://cdn.chiroscript.ai/logo/logo.svg"
            className="mt-4"
            width={200}
            height={200}
          />
          {isGreater(screen.screen, TRY_NOW) && !isLogin && (
            <button className="w-[40px] h-[40px] fixed left-4 top-4 border-gray-400 rounded-md border-2 justify-center items-center flex transition-all duration-500 ease-in-out shadow-md hover:shadow-none">
              <svg
                width="18"
                height="18"
                viewBox="0 0 16 16"
                fill="#004CA9"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 13.78C9.87335 13.78 9.74669 13.7333 9.64669 13.6333L5.30002 9.28668C4.59335 8.58001 4.59335 7.42001 5.30002 6.71335L9.64669 2.36668C9.84002 2.17335 10.16 2.17335 10.3534 2.36668C10.5467 2.56001 10.5467 2.88001 10.3534 3.07335L6.00669 7.42001C5.68669 7.74001 5.68669 8.26001 6.00669 8.58001L10.3534 12.9267C10.5467 13.12 10.5467 13.44 10.3534 13.6333C10.2534 13.7267 10.1267 13.78 10 13.78Z"
                  fill="#004CA9"
                />
              </svg>
            </button>
          )}
          {isGreater(screen.screen, TRY_NOW) && !isLogin && (
            <div className="flex items-center gap-4">
              {Object.keys(components).map((item, index, arr) => (
                <div
                  key={item}
                  className={twMerge(
                    "relative w-[59px] h-[6px] bg-gray-200",
                    clsx(
                      { "rounded-l-full": index == 0 },
                      { "rounded-r-full": index + 1 == arr.length }
                    )
                  )}
                >
                  <div
                    className={twMerge(
                      "absolute inset-0 from-gradient-start to-gradient-end from-[0%] to-[100%] bg-gradient-90 duration-200 transition origin-left scale-x-0",
                      clsx(
                        {
                          "rounded-l-full": index == 0,
                        },
                        {
                          "scale-x-100": isGreater(screen.screen, item),
                        },
                        {
                          "rounded-r-full": index + 1 == arr.length,
                        }
                      )
                    )}
                  ></div>
                </div>
              ))}
            </div>
          )}
        </div>
        {loading && (
          <div className="flex flex-col justify-center items-center my-auto gap-4">
            <Loading classes={"w-[4rem]"} />
            <p className="text-font-600 text-[90%]">
              Please wait while we authenticate you!
            </p>
          </div>
        )}

        {!loading && (
          <div className="flex-col w-[600px] h-full justify-center gap-3 mx-auto flex">
            <div className="relative mx-auto flex flex-col gap-2">
              <EmailIcon className="w-[90px] h-[90px]" />
            </div>
            <div className="flex flex-row-reverse mt-4 gap-4">
              <div className="flex flex-col gap-2 w-full">
                <label
                  htmlFor="last"
                  className="font-inter text-font-200 select-none text-[90%] ml-[2px]"
                >
                  Last Name *
                </label>
                <input
                  type="text"
                  id="last"
                  className="px-4 py-3 rounded-md bg-white border-[1px] border-stroke border-solid outline-none text-black placeholder:text-[85%] placeholder:text-font-200/85"
                  placeholder="Last Name"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label
                  htmlFor="first"
                  className="font-inter text-font-200 select-none text-[90%] ml-[2px]"
                >
                  First Name *
                </label>
                <input
                  type="text"
                  id="first"
                  className="px-4 py-3 rounded-md bg-white border-[1px] border-stroke border-solid outline-none text-black placeholder:text-[85%] placeholder:text-font-200/85"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label
                htmlFor="email"
                className="font-inter text-font-200 select-none text-[90%] ml-[2px]"
              >
                Email Address *
              </label>
              <input
                type="email"
                id="email"
                className="px-4 py-3 rounded-md bg-white border-[1px] border-stroke border-solid outline-none text-black placeholder:text-[85%] placeholder:text-font-200/85"
                placeholder="user@example.com"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                htmlFor="password"
                className="font-inter text-font-200 select-none text-[90%] ml-[2px]"
              >
                Your Password *
              </label>
              <input
                type="password"
                id="password"
                className="px-4 py-3 rounded-md bg-white border-[1px] border-stroke border-solid outline-none placeholder:text-[85%] text-black placeholder:text-font-200/85"
                placeholder="Strong password"
              />
            </div>
            <button className="btn flex flex-row mt-3 mx-auto bg-accent stroke-none outline-none border-none w-[320px] hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.2rem] font-inter font-bold transition-all duration-500 ease-in-out group justify-center">
              <span>Get Started</span>
            </button>
            <div className="flex flex-row items-center justify-around gap-6 mt-1">
              <div className="h-[1px] w-full border-[1px] border-gradient-start/80"></div>
              <p className="w-fit bg-transparent font-dm font-semibold text-primary">
                or
              </p>
              <div className="h-[1px] w-full border-[1px] border-gradient-end/80"></div>
            </div>
            <div className="flex flex-row justify-center gap-[1.875rem]">
              <button
                className=" w-[320px] mx-auto justify-center inline-flex cursor-pointer items-center gap-4 rounded-[0.4rem] bg-white p-3 font-dm text-[1rem] font-medium font-inter text-black shadow-sm transition-shadow duration-[500ms] hover:shadow-none"
                onClick={onGoogle}
              >
                <img
                  src="https://cdn.bookaible.ai/icons/google.svg"
                  className="w-[1.2rem]"
                  width={"19.2px"}
                  loading="lazy"
                ></img>
                <p>Continue with Google</p>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function Policy({ data, next }) {
  const { set, get } = data;

  const obj = get();

  const health = useRef(null);
  const policy = useRef(null);

  function click() {
    const [whatHealth, whatPolicy] = [
      health.current.value,
      policy.current.value,
    ];

    if (whatHealth && whatPolicy) {
      next();
      return;
    }
  }

  return (
    <div className="relative flex-grow h-full w-full flex flex-col items-center justify-center z-10 gap-8 mx-auto">
      <div className="relative">
        <PrivacyIcon className="w-[90px] h-[90px]" />
      </div>
      <div className="flex flex-col items-center justify-center *:leading-snug *:font-inter *:text-center">
        <h5>Date & Privacy</h5>
        <div className="flex flex-col gap-1 mt-4 *:font-inter *:font-[1rem] *:text-font-200">
          <p>We take privacy seriously.</p>
          <p>We're also HiPPA & GDPR compliant.</p>
        </div>
      </div>
      <div className="flex justify-center flex-col gap-4 w-[320px] ml-4">
        <div className="flex flex-row gap-2 *:cursor-pointer">
          <div className="flex flex-row gap-4 items-center *:cursor-pointer">
            <input
              type="checkbox"
              defaultChecked={true}
              className="h-3 w-3 hover:accent-primary/95 accent-primary appearance-auto"
              id="health"
              ref={health}
            />
            <label
              htmlFor={"health"}
              className="font-inter text-font-200  select-none"
            >
              I am a professional health provider
            </label>
          </div>
        </div>
        <div className="flex flex-row gap-4 items-center *:cursor-pointer w-[320px]">
          <input
            type="checkbox"
            defaultChecked={true}
            className="h-3 w-3 hover:accent-primary/95 accent-primary appearance-auto"
            id="policy"
            ref={policy}
          />
          <label
            htmlFor={"policy"}
            className="font-inter text-font-200  select-none"
          >
            I agree to the Terms of service and the Data Protection Agreement
          </label>
        </div>
      </div>
      <button
        className="btn flex flex-row mt-3 bg-accent stroke-none outline-none border-none w-[320px] hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.2rem] font-inter font-bold transition-all duration-500 ease-in-out group justify-center"
        onClick={click}
      >
        <span>Next</span>
      </button>
    </div>
  );
}

function Info({ data, next }) {
  const country = useRef(null);
  const ehr = useRef(null);

  function click() {
    const whatCountry = country.current.getValue();
    const whatEhr = ehr.current.getValue();

    if (Array.isArray(whatCountry) && whatCountry.length == 0) {
      const current = toast("Please select a country to continue", {
        ...errorOpts,
      });

      return;
    }

    const { set, get } = data;

    const obj = get();

    set({
      ...obj,
      country: whatCountry[0].value,
      ehr:
        Array.isArray(whatEhr) && whatEhr.length > 0 ? whatEhr[0].value : null,
    });

    if (typeof next === "function") {
      next();
    }
  }

  return (
    <div className="relative flex-grow h-full w-full flex flex-col items-center justify-center z-10 gap-4 mx-auto">
      <div className="relative">
        <UserIcon className="w-[90px] h-[90px]" />
      </div>
      <div className="flex flex-col items-center justify-center *:leading-snug *:font-inter *:text-center">
        <h5>Tell us more</h5>
        <h5>about your practice</h5>
      </div>
      <div className="flex justify-center flex-col items-center gap-6 mt-4">
        <div className="flex flex-col gap-2 *:cursor-pointer">
          <label className="font-inter text-font-200 font-semibold text-[90%]">
            Country of practice *
          </label>
          <Select
            isSearchable={true}
            className="w-[320px] !cursor-pointer"
            options={[
              { value: "India", label: "India" },
              { value: "USA", label: "United States of America" },
              { value: "Canada", label: "Canada" },
            ]}
            defaultValue={{ value: "USA", label: "United States of America" }}
            required={true}
            ref={country}
          />
        </div>
        <div className="flex flex-col gap-2 *:cursor-pointer text-[90%]">
          <label className="font-inter text-font-200 font-semibold">EHR</label>
          <Select
            isSearchable={true}
            className="w-[320px] !cursor-pointer"
            options={[
              { value: "SIMPLEPRACTICE", label: "SimplePractice" },
              { value: "OPUS", label: "Opus" },
            ]}
            ref={ehr}
          />
        </div>
      </div>
      <button
        className="btn flex flex-row mt-3 bg-accent stroke-none outline-none border-none w-[320px] hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.2rem] font-inter font-bold transition-all duration-500 ease-in-out group justify-center"
        onClick={click}
      >
        <span>Next</span>
      </button>
    </div>
  );
}

function TryNow({ data, next }) {
  function click() {
    if (typeof next === "function") {
      next();
    }
  }

  return (
    <div className="relative flex-grow h-full w-full flex flex-col items-center justify-center z-10 gap-10 mx-auto">
      <div className="relative">
        <Circle className="w-[100px] h-[100px]" />
        <Hello className="w-[100px] h-[100px] absolute shadow-none top-0 mt-2" />
      </div>
      <div className="flex flex-col items-center justify-center gap-5">
        <h3 className="font-inter font-semibold">Welcome</h3>
        <div className="text-center *:font-inter *:text-[1rem] text-font-200">
          <p>Get a structured medical note from your</p>
          <p>consultation, in seconds</p>
        </div>
        <div className="flex flex-col gap-3">
          <button
            className="btn flex flex-row bg-accent stroke-none outline-none border-none w-[320px] hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.2rem] font-inter font-bold transition-all duration-500 ease-in-out group justify-center"
            onClick={click}
          >
            <span className="translate-x-1">Try Now</span>
            <svg
              width="18"
              height="18"
              viewBox="0 0 16 16"
              className="rotate-180 group-hover:translate-x-1 duration-500"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 13.78C9.87335 13.78 9.74669 13.7333 9.64669 13.6333L5.30002 9.28668C4.59335 8.58001 4.59335 7.42001 5.30002 6.71335L9.64669 2.36668C9.84002 2.17335 10.16 2.17335 10.3534 2.36668C10.5467 2.56001 10.5467 2.88001 10.3534 3.07335L6.00669 7.42001C5.68669 7.74001 5.68669 8.26001 6.00669 8.58001L10.3534 12.9267C10.5467 13.12 10.5467 13.44 10.3534 13.6333C10.2534 13.7267 10.1267 13.78 10 13.78Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <p className="flex font-inter text-font-200 gap-2 justify-center">
            Already have an account?
            <a
              href="/login"
              className="link link-hover text-gradient-start underline text-center"
            >
              Sign in
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
