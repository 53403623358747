import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export default function Loading({ classes = [] }) {  
	if(typeof classes === "string") {
		classes = classes.split(' ');
	}
	return (
		<span className={twMerge("loading loading-ring loading-lg text-primary", clsx(...classes))}></span>
	)
}
