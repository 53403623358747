export const processOpts = {
    'position': 'top-right',
    'autoClose': false,
    'pauseOnFocusLoss': false,
    'pauseOnHover': false,
    'closeOnClick': false,
    'hideProgressBar': true,
    'closeButton': false,
    'type': 'info',
    'theme': 'dark',
    'draggable': !import.meta.PROD,
    'isLoading': true,
}


export const successOpts = {
    'position': 'top-right',
    'autoClose': 3000,
    'pauseOnFocusLoss': true,
    'pauseOnHover': false,
    'closeOnClick': true,
    'hideProgressBar': false,
    'closeButton': false,
    'theme': "colored",
    'type': 'success',
    'draggable': true,
    'isLoading': false,
}


export const errorOpts = {

    'position': 'top-right',
    'autoClose': 5000,
    'pauseOnFocusLoss': true,
    'pauseOnHover': true,
    'closeOnClick': false,
    'hideProgressBar': false,
    'closeButton': false,
    'theme': "colored",
    'type': 'error',
    'draggable': !import.meta.PROD,
    'isLoading': false,
}