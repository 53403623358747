import React from "react";
import { auth } from "../../util/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  return <Note />;
}

function Note() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row w-full h-full bg-page p-4 overflow-hidden gap-8">
      <aside className="h-full w-[30%] bg-white flex flex-col rounded-md">
        <img
          src="https://cdn.chiroscript.ai/logo/logo.svg"
          className="mt-4 mx-auto"
          width={200}
          height={200}
        />
        <div className="flex w-full flex-col h-full overflow-y-auto">
          <ul className="menu mt-10 flex gap-3">
            <li>
              <details open>
                <summary className="bg-stroke hover:bg-stroke">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.4345 4.03448C19.5668 4.2424 19.3401 4.48461 19.0998 4.43C18.6298 4.29 18.1098 4.22 17.5798 4.22H14.2796C14.1223 4.22 13.9743 4.14605 13.8798 4.02037L12.7298 2.49C12.589 2.29044 12.7221 2 12.9664 2H15.7198C17.2808 2 18.6559 2.81073 19.4345 4.03448Z"
                      fill="#676E80"
                    />
                    <path
                      d="M19.8476 6.94559L19.8476 6.9456L19.8511 6.94808C20.8537 7.65786 21.5 8.8182 21.5 10.14V17.58C21.5 19.7439 19.7439 21.5 17.58 21.5H6.42C4.25614 21.5 2.5 19.7439 2.5 17.58V6.42C2.5 4.25614 4.25614 2.5 6.42 2.5H8.74C9.50213 2.5 9.9401 2.62406 10.2501 2.80535C10.5679 2.99123 10.8066 3.26718 11.1294 3.69926L11.1305 3.70069L12.5305 5.56069L12.5312 5.56155C12.5407 5.5741 12.5503 5.58694 12.5601 5.60001C12.6842 5.76525 12.8366 5.96828 13.0611 6.08631C13.309 6.21667 13.5907 6.22 13.86 6.22H17.58C17.9156 6.22 18.2405 6.26407 18.5562 6.35176L18.5635 6.35379L18.5709 6.35561C19.0374 6.47002 19.4687 6.67243 19.8476 6.94559ZM9.6 16.84H14.39C15.0561 16.84 15.59 16.3061 15.59 15.64C15.59 14.9885 15.0608 14.44 14.39 14.44H9.6C8.91989 14.44 8.41 14.998 8.41 15.64C8.41 16.2967 8.92448 16.84 9.6 16.84Z"
                      stroke="#676E80"
                    />
                  </svg>
                  <span className="w-full">Fields</span>
                </summary>
                <ul className="before:bg-[#0073CE] before:w-[1.5px]">
                  <li>
                    <button
                      role="link"
                      className="text-font-200 hover:bg-accent/15 mt-2 rounded-full"
                    >
                      Patient Demographics
                    </button>
                  </li>
                </ul>
              </details>
            </li>
            <li className="hover:bg-accent/15 rounded-full flex flex-row items-center">
              <a className="font-inter text-font-200">
                <svg
                  className="z-10 w-[18px] h-[18px]"
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <path
                    d="M14.9348 4.17896H11.0648C7.44977 4.17896 4.50977 7.39645 4.50977 11.3565V17.0715C4.50977 17.2704 4.58878 17.4611 4.72944 17.6018C4.87009 17.7424 5.06085 17.8215 5.25977 17.8215H6.28727C6.72625 17.8195 7.1467 17.6442 7.45712 17.3338C7.76753 17.0234 7.94279 16.6029 7.94477 16.164V12.9765C7.94477 12.7588 7.90189 12.5433 7.8186 12.3422C7.7353 12.1411 7.61321 11.9583 7.4593 11.8044C7.30538 11.6505 7.12266 11.5284 6.92156 11.4451C6.72047 11.3618 6.50493 11.319 6.28727 11.319H6.00977C6.00977 8.20646 8.25977 5.67895 11.0648 5.67895H14.9348C17.7098 5.67895 19.9673 8.20646 19.9898 11.319H19.7498C19.5321 11.319 19.3166 11.3618 19.1155 11.4451C18.9144 11.5284 18.7316 11.6505 18.5777 11.8044C18.4238 11.9583 18.3017 12.1411 18.2184 12.3422C18.1351 12.5433 18.0923 12.7588 18.0923 12.9765V16.164C18.0942 16.6029 18.2695 17.0234 18.5799 17.3338C18.8903 17.6442 19.3108 17.8195 19.7498 17.8215H19.8473C19.6258 18.9686 19.0244 20.0078 18.1399 20.7711C17.2554 21.5345 16.1395 21.9776 14.9723 22.029H12.9998C12.8009 22.029 12.6101 22.108 12.4694 22.2486C12.3288 22.3893 12.2498 22.58 12.2498 22.779C12.2498 22.9779 12.3288 23.1686 12.4694 23.3093C12.6101 23.4499 12.8009 23.529 12.9998 23.529H14.9348C18.2273 23.529 20.9348 20.8515 21.4148 17.3865C21.4624 17.2882 21.488 17.1807 21.4898 17.0715V11.3565C21.4898 7.39645 18.5498 4.17896 14.9348 4.17896Z"
                    fill="#676E80"
                  />
                  <path
                    d="M14.98 21.414C15.1789 21.414 15.3697 21.335 15.5103 21.1943C15.651 21.0537 15.73 20.8629 15.73 20.664C15.73 20.4651 15.651 20.2743 15.5103 20.1337C15.3697 19.993 15.1789 19.914 14.98 19.914C14.98 19.914 14.23 19.914 13.03 19.869C12.9303 19.865 12.8307 19.8809 12.7372 19.9159C12.6437 19.9508 12.5582 20.0041 12.4855 20.0726C12.4129 20.141 12.3546 20.2233 12.3142 20.3145C12.2737 20.4058 12.2519 20.5042 12.25 20.604C12.25 20.8029 12.329 20.9937 12.4697 21.1343C12.6103 21.275 12.8011 21.354 13 21.354C14.1925 21.414 14.98 21.414 14.98 21.414Z"
                    fill="#676E80"
                  />
                </svg>
                Transcription
              </a>
            </li>
            <li>
              <details open>
                <summary className="bg-stroke hover:bg-stroke">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.7132 0.763612C15.2208 0.274502 14.5549 0 13.8608 0C13.1668 0 12.5009 0.274502 12.0084 0.763612L0.689399 12.0827C0.583228 12.1923 0.513396 12.3319 0.489399 12.4827L0.00844693 15.6589C-0.00734218 15.7606 -0.000944214 15.8646 0.0272023 15.9636C0.0553488 16.0627 0.104578 16.1545 0.171517 16.2327C0.238455 16.311 0.32152 16.3738 0.415018 16.4169C0.508516 16.4601 0.610237 16.4825 0.713209 16.4827H0.822733L3.99416 16.0065C4.14487 15.9825 4.28454 15.9126 4.39416 15.8065L15.7132 4.46837C16.2023 3.97594 16.4768 3.31005 16.4768 2.61599C16.4768 1.92193 16.2023 1.25604 15.7132 0.763612ZM3.5513 14.6112L1.56559 14.9112L1.86559 12.9255L11.1608 3.63504L12.8418 5.31599L3.5513 14.6112ZM14.7037 3.45885L13.518 4.64456L11.8323 2.95885L13.018 1.77314C13.2415 1.5496 13.5447 1.42401 13.8608 1.42401C14.177 1.42401 14.4801 1.5496 14.7037 1.77314C14.9272 1.99668 15.0528 2.29986 15.0528 2.61599C15.0528 2.93213 14.9272 3.23531 14.7037 3.45885Z"
                      fill="#676E80"
                    />
                  </svg>
                  <span className="w-full">Patient Note</span>
                </summary>
                <ul className="before:bg-[#0073CE] before:w-[1.5px]">
                  <li>
                    <button
                      role="link"
                      className="text-font-200 hover:bg-accent/15 mt-2 rounded-full"
                    >
                      Current Visit Summary
                    </button>
                  </li>
                  <li>
                    <button
                      role="link"
                      className="text-font-200 hover:bg-accent/15 mt-2 rounded-full"
                    >
                      Subjective
                    </button>
                  </li>
                  <li>
                    <button
                      role="link"
                      className="text-font-200 hover:bg-accent/15 mt-2 rounded-full"
                    >
                      Objective
                    </button>
                  </li>
                  <li>
                    <button
                      role="link"
                      className="text-font-200 hover:bg-accent/15 mt-2 rounded-full"
                    >
                      Action
                    </button>
                  </li>
                  <li>
                    <button
                      role="link"
                      className="text-font-200 hover:bg-accent/15 mt-2 rounded-full"
                    >
                      Plan
                    </button>
                  </li>
                </ul>
              </details>
            </li>
            <li>
              <details open>
                <summary className="bg-stroke hover:bg-stroke">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 22 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5625 19.3125V8.4375C17.5625 5.85281 15.4597 3.75 12.875 3.75H5.375C2.79031 3.75 0.6875 5.85281 0.6875 8.4375V19.3125C0.6875 21.8972 2.79031 24 5.375 24H12.875C15.4597 24 17.5625 21.8972 17.5625 19.3125ZM2.5625 19.3125V8.4375C2.5625 6.88669 3.82419 5.625 5.375 5.625H12.875C14.4258 5.625 15.6875 6.88669 15.6875 8.4375V19.3125C15.6875 20.8633 14.4258 22.125 12.875 22.125H5.375C3.82419 22.125 2.5625 20.8633 2.5625 19.3125Z"
                      fill="#676E80"
                    />
                    <path
                      d="M21.3125 19.5V8.25C21.3125 7.73222 20.8928 7.3125 20.375 7.3125C19.8572 7.3125 19.4375 7.73222 19.4375 8.25V19.5C19.4375 20.0178 19.8572 20.4375 20.375 20.4375C20.8928 20.4375 21.3125 20.0178 21.3125 19.5Z"
                      fill="#676E80"
                    />
                    <path
                      d="M11 1.875C11.5178 1.875 11.9375 1.45528 11.9375 0.9375C11.9375 0.419719 11.5178 0 11 0H7.25C6.73222 0 6.3125 0.419719 6.3125 0.9375C6.3125 1.45528 6.73222 1.875 7.25 1.875H11Z"
                      fill="#676E80"
                    />
                    <path
                      d="M5.375 16.6875H12.875C13.3928 16.6875 13.8125 16.2678 13.8125 15.75C13.8125 15.2322 13.3928 14.8125 12.875 14.8125H5.375C4.85722 14.8125 4.4375 15.2322 4.4375 15.75C4.4375 16.2678 4.85722 16.6875 5.375 16.6875Z"
                      fill="#676E80"
                    />
                    <path
                      d="M13.8125 19.5C13.8125 18.9822 13.3928 18.5625 12.875 18.5625H5.375C4.85722 18.5625 4.4375 18.9822 4.4375 19.5C4.4375 20.0178 4.85722 20.4375 5.375 20.4375H12.875C13.3928 20.4375 13.8125 20.0178 13.8125 19.5Z"
                      fill="#676E80"
                    />
                    <path
                      d="M9.125 12.9375C9.64278 12.9375 10.0625 12.5178 10.0625 12V11.0625H11C11.5178 11.0625 11.9375 10.6428 11.9375 10.125C11.9375 9.60722 11.5178 9.1875 11 9.1875H10.0625V8.25C10.0625 7.73222 9.64278 7.3125 9.125 7.3125C8.60722 7.3125 8.1875 7.73222 8.1875 8.25V9.1875H7.25C6.73222 9.1875 6.3125 9.60722 6.3125 10.125C6.3125 10.6428 6.73222 11.0625 7.25 11.0625H8.1875V12C8.1875 12.5178 8.60722 12.9375 9.125 12.9375Z"
                      fill="#676E80"
                    />
                  </svg>

                  <span className="w-full">Patient Instructions</span>
                </summary>
                <ul className="before:bg-[#0073CE] before:w-[1.5px]">
                  <li>
                    <button
                      role="link"
                      className="text-font-200 hover:bg-accent/15 mt-2 rounded-full"
                    >
                      Instructions
                    </button>
                  </li>
                  <li>
                    <button
                      role="link"
                      className="text-font-200 hover:bg-accent/15 mt-2 rounded-full"
                    >
                      Summary
                    </button>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
        </div>
        <div className="w-full flex flex-col bg-secondary p-4 rounded-b-md">
          <div className="flex flex-row items-center gap-4">
            <img
              src={"https://placehold.co/48x48"}
              className="rounded-[50%]"
              width={"48px"}
              height={"48px"}
            />
            <p className="font-inter text-font-200">
              {auth?.currentUser?.displayName}
            </p>
          </div>
        </div>
      </aside>
      <main className="h-[100vh] overflow-y-auto w-full flex flex-col gap-6">
        <div className="bg-white px-8 rounded-md min-h-[50%]">
          <h6
            src="https://cdn.chiroscript.ai/logo/logo.svg"
            className="text-font-400 font-inter mt-4"
          >
            Personal Details
          </h6>
		  <div className="flex flex-row w-full gap-4 mt-4">
			<div className="flex flex-col gap-2 w-full">
				<label className="font-inter text-font-200 text-[95%]" htmlFor="firstName">First Name</label>
				<input className="rounded-md px-4 py-3 bg-white border-[1px] border-stroke border-solid outline-none text-black placeholder:text-[85%] placeholder:text-font-200/85" type="text" id="firstName" />
			</div>
			<div className="flex flex-col gap-2 w-full">
				<label className="font-inter text-font-200 text-[95%]" htmlFor="lastName">Last Name</label>
				<input className="rounded-md px-4 py-3 bg-white border-[1px] border-stroke border-solid outline-none text-black placeholder:text-[85%] placeholder:text-font-200/85" type="text" id="lastName" />
			</div>
			<div className="flex flex-col gap-2 w-full">
				<label className="font-inter text-font-200 text-[95%]" htmlFor="dob">Date of Birth</label>
				<input className="rounded-md px-4 py-3 bg-white border-[1px] border-stroke border-solid outline-none text-black placeholder:text-[85%] placeholder:text-font-200/85" type="text" id="dob" />
			</div>
		  </div>
        </div>
		<div className="bg-white px-8 rounded-md min-h-[50%]">
          <h6
            src="https://cdn.chiroscript.ai/logo/logo.svg"
            className="text-font-400 font-inter mt-4"
          >
            Personal Details
          </h6>
        </div>
		<div className="bg-white px-8 rounded-md h-[50%]">
          <h6
            src="https://cdn.chiroscript.ai/logo/logo.svg"
            className="text-font-400 font-inter mt-4"
          >
            Personal Details
          </h6>
        </div>
		<div className="bg-white px-8 rounded-md h-[50%]">
          <h6
            src="https://cdn.chiroscript.ai/logo/logo.svg"
            className="text-font-400 font-inter mt-4"
          >
            Personal Details
          </h6>
        </div>
      </main>
      <aside className="h-full w-[30%]"></aside>
    </div>
  );
}
